<template>
  <div>
    <v-card
      max-width="500"
      min-height="625"
      :class="$vuetify.breakpoint.smAndUp ? 'card-radius' : ''"
      class="my-md-8 mx-auto"
      color="#C3FFF5"
      flat
    >
      <v-row class="justify-lg-end justify-space-between mx-6 pt-4" >
         <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="primary--text" large  @click="$router.go(-1)" >mdi-arrow-left-drop-circle-outline</v-icon >
        <lang-swicther />
      </v-row>
      <v-card-text class="pt-16" >
        <v-img
          :src="require('../../../assets/logo.png')"
          width="90px"
          contain
          class="mx-auto"
        />
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col class="text-center mx-12">
            <h3>{{
              $t('forgetPassword.title')
              }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :class="$vuetify.breakpoint.smAndDown ? 
            'text-center' : 'mx-12 text-center'"
          >
            {{ $t('forgetPassword.subTitle') }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-hover v-slot="{hover}">

        <v-card
          flat
          class="primary mx-auto mb-5 mt-n3"
          :class="{'darken-1':hover}"
          :to="{name:'PasswordPhoneOtp'}"
          height="70"
          width="260"
        >
          <v-card-text>
            <v-row>
              <v-col class="mt-n2" cols="2">
                <v-icon class="black--text" large>
                  mdi-cellphone
                </v-icon>
              </v-col>
              <v-col cols="10" >
                {{ $t('forgetPassword.viaSms') }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        </v-hover>

<v-hover v-slot="{hover}" >
        <v-card
          flat
          class="primary mx-auto"
          :class="{'darken-1':hover}"
          :to="{name:'PasswordEmailOtp'}"
          height="70"
          width="260"
        >
          <v-card-text>
            <v-row>
              <v-col class="mt-n2" cols="2">
                <v-icon class="black--text" large>
                  mdi-email-box
                </v-icon>
              </v-col>
              <v-col cols="10" >
                {{ $t('forgetPassword.viaEmail')  }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        </v-hover>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btn_loading: false,
      errorFromApi: null,
      phoneNumber: ''
    }
  },
  components: {
    "lang-swicther": () => import("@/core/components/LangSwitch")
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      this.btn_loading = true;
    }
  }
}
</script>

<style scoped>
</style>
